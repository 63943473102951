import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    Inject,
    ViewChild,
    AfterViewInit,
    OnChanges, SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import { MultimediaDialogComponent } from '../../../multimedia/dialog/multimedia-dialog.component';

import { debounceTime, distinctUntilChanged, map, mergeMap, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ModelService } from '../../../services/model.service';
import { IndexadoService } from '../../../services/indexado.service';

import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';
import { BroadcastService } from '../../../services/bradcast.services';
import { Observable, of } from 'rxjs';
import Fuse from 'fuse.js'
import { ModalService } from 'app/services/modal.service';
import { CONFIRM_DELETE_ELEMENT, INFO_NOT_TABLE_ELEMENT } from 'app/Messages';
import { ICustomVersion, IVersion } from 'app/main/project/project.model';
import { CustomVersionNameFilterPipe } from '../indexado-custom-name-pipe';

@Component({
    selector: 'indexado-sidebar',
    templateUrl: './indexado-sidebar.component.html',
    styleUrls: ['./indexado-sidebar.component.scss']
})
export class IndexadoSidebarComponent implements OnInit {


    @Input() row: any;
    @Input() card: any;
    @Input() content: any;
    @Input() element: any;
    @Input() cell: any;
    @Input() tableRow: any;
    @Input() page: any;
    @Input() multimedia: any;
    @Input() modelProperties: any;
    @Input() modelPropertiesValue: any;
    @Input() onChangeSelectedLang: any;
    @Input() onChangeSelectedVersion: any;
    @Input() onChangeCustomVersionName: any;
    @Input() indexadoV5: boolean;
    @Input() indexadoVersions: any;
    @Input() selectedVersion: string;
    @Input() selectedCustomVersionName: string;
    @Input() setArrVersions: any;
    //@Input() modelLanguageList: any;

    @Input() activeTab: any;
    @Input() arrVersions: any[];
    @Input() customVersions: any;


    @Output() rowChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() pageChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() cardChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() contentChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() elementChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() cellChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() tableRowChangeHandler: EventEmitter<any> = new EventEmitter();
    @Output() indexadoChangeHandler: EventEmitter<any> = new EventEmitter();


    public filteredOptions: Observable<any>;

    propertiesControl = new FormControl();
    propertiesControl2 = new FormControl();

    filteredOptionsProperties: Observable<string[]>;
    filteredOptionsProperties2: Observable<string[]>;
    selectedIndex: number = 1;
    _modelLanguageList: any;

    @Input() set modelLanguageList(modelLanguageList: number) {
        this._modelLanguageList = modelLanguageList;
        // this.isLangAvailable();
    }

    itemCtrl: FormControl;
    filteredCustomVersions: Observable<any>;
    showAddButton: boolean = false;
    maxGradientRows: number = 3;

    prompt = 'Presione <enter> para agregar"';
    constructor(
        private modelService: ModelService,
        private _fuseProgressBarService: FuseProgressBarService,
        private broadcastService: BroadcastService,
        private modalDialogService: ModalService,
        private indexadoService: IndexadoService
    ) {
        this.itemCtrl = new FormControl();

        this.filteredCustomVersions = this.itemCtrl.valueChanges
            .pipe(
                startWith(''),
                map(item => item ? this.filterItems(item) : this.customVersions.slice())
            );

    }
    filterItems(name: string) {
        let results = this.customVersions.filter(item =>
            item.toLowerCase().indexOf(name.toLowerCase()) === 0);

        this.showAddButton = results.length === 0;
        if (this.showAddButton) {
            results = [this.prompt + name + '"'];
        }

        return results;
    }

    optionSelected(option) {
        if (option.value.indexOf(this.prompt) === 0) {
            this.addOption();
        }
    }

    addOption() {
        this.modalDialogService.selectModalType('ADD_NEW_ELEMENT', {
            title: 'Agregar un nombre personalizado',
            message: '',
            error: {}
        }, (args) => {
            const { message } = args;
            if (message) {
                this.modalDialogService.selectModalType('BUTTONCONFIRM',
                    `Esta seguro que desea colocar a la version ${this.selectedVersion} el nombre ${message}?`,
                    (resp: any) => {
                        if (resp) {
                            
                            this.setArrVersions({
                                versions: message,
                                addVersion: true
                            })
                        }
                    }
                )
            }
        });
        /*
        let option = this.removePromptFromOption(this.itemCtrl.value);
        if (!this.customVersions.some(entry => entry === option)) {
            const index = this.customVersions.push(option) - 1;
            this.itemCtrl.setValue(this.customVersions[index]);
        }
        */
    }

    removePromptFromOption(option) {
        if (option.startsWith(this.prompt)) {
            option = option.substring(this.prompt.length, option.length - 1);
        }
        return option;
    }
    searchControl = new FormControl();


    contentsLength: any;


    autoTicks = false;
    disabled = false;
    invert = false;
    max = 100;
    min = 0;
    showTicks = false;
    step = 1;
    thumbLabel = false;
    value = 0;
    vertical = false;
    subscription;
    loadingModels = false;
    fontFamilies;
    languages: any;
    selectedLanguage: any;
    canTranslate = true;


    @Input() onCreateNewTranslation: (args: any) => void;
    private _defaultLang: string;


    @Input()
    set defaultLang(val: any) {

        this._defaultLang = val;
        if (this.languages) {

            this.selectedLanguage = this.defaultLang ? this.languages.find(l => l.code === val) : this.languages[0];
            console.log('this.selectedLanguage :', this.selectedLanguage);
        }
    }

    get defaultLang(): any {
        return this._defaultLang;
    }
    isLangAvailable() {


        if (this._modelLanguageList) {
            // this.canTranslate= this._modelLanguageList.indexOf(this.selectedLanguage.code)==-1;

            // call uper function
        }


    }
    ngOnInit() {
        this.loadingModels = true;

        this.fontFamilies = [
            {
                name: 'HPSimplified',
                value: 'HPSimplified'
            },
            {
                name: 'HPSimplifiedLight',
                value: 'HPSimplifiedLight'
            },
            {
                name: 'FormaDJRCyrillic',
                value: 'FormaDJRCyrillic'
            },
            {
                name: 'FormaDJRCyrillicLight',
                value: 'FormaDJRCyrillicLight'
            },
            {
                name: 'MicrosoftSegoeUi',
                value: 'MicrosoftSegoeUi'
            },
            {
                name: 'MicrosoftSegoeUiLight',
                value: 'MicrosoftSegoeUiLight'
            },
            {
                name: 'MicrosoftSegoeText',
                value: 'MicrosoftSegoeText'
            },
            {
                name: 'MicrosoftSegoeBold',
                value: 'MicrosoftSegoeBold'
            },
            {
                name: 'MideaGothanBookItalic',
                value: 'MideaGothanBookItalic'
            },
            {
                name: 'MideaGothanLight',
                value: 'MideaGothanLight'
            },
            {
                name: 'MideaGothanBookText',
                value: 'MideaGothanBookText'
            },
            {
                name: 'MideaGothanboldBoldtitle',
                value: 'MideaGothanboldBoldtitle'
            },
            {
                name: 'OsterHelveticaItalic',
                value: 'OsterHelveticaItalic'
            },
            {
                name: 'OsterHelveticaLight',
                value: 'OsterHelveticaLight'
            },
            {
                name: 'OsterHelveticaText',
                value: 'OsterHelveticaText'
            },
            {
                name: 'OsterHelveticaTextBold',
                value: 'OsterHelveticaTextBold'
            },
            {
                name: 'FormaDJRDisplay-Regular',
                value: 'FormaDJRDisplay-Regular'
            },
            {
                name: 'FormaDJRMicro-Regular',
                value: 'FormaDJRMicro-Regular'
            },
            {
                name: 'RBNo3-1-Medium',
                value: 'RBNo3-1-Medium'
            },
            {
                name: 'Proxima-Nova-Regular',
                value: 'Proxima-Nova-Regular'
            },
            {
                name: 'Gotham-Black-Regular',
                value: 'Gotham-Black-Regular'
            },
            {
                name: 'HelveticaNeue',
                value: 'HelveticaNeue'
            },
            {
                name: 'KorolevBold',
                value: 'KorolevBold'
            },
            {
                name: 'VeetDisplay',
                value: 'VeetDisplay'
            }, 
            {
                name: 'One-Night-Sans-Black',
                value: 'One-Night-Sans-Black'
            },
            {
                name: 'OneNightSans-Condensed',
                value: 'OneNightSans-Condensed'
            },
            {
                name: 'OneNightSans-Extended',
                value: 'OneNightSans-Extended'
            },
            {
                name: 'OneNightSans-ExtendedBlack',
                value: 'OneNightSans-ExtendedBlack'
            },
            {
                name: 'QuatroSlab-Bold',
                value: 'QuatroSlab-Bold'
            },
            {
                name: 'QuatroSlab-Regular',
                value: 'QuatroSlab-Regular'
            },
            {
                name: 'Helvetica',
                value: 'Helvetica'
            },
            {
                name: 'OpenSans-Bold',
                value: 'OpenSans-Bold'
            },
            {
                name: 'CalpsSans-Regular',
                value: 'CalpsSans-Regular'
            },
            {
                name: 'CalpsSansSlim-Medium',
                value: 'CalpsSansSlim-Medium'
            },
            {
                name: 'CalpsSansSlim-Regular',
                value: 'CalpsSansSlim-Regular'
            },
            {
                name: 'Lithos-Pro-Black',
                value: 'Lithos-Pro-Black'
            },
            {
                name: 'Optima',
                value: 'Optima'
            },
            {
                name: 'Optima-B',
                value: 'Optima-B'
            },
            {
                name: 'Optima-Medium',
                value: 'Optima-Medium'
            },
            {
                name: 'Optima-Italic',
                value: 'Optima-Italic'
            },
            {
                name: 'Inter-18pt-Medium',
                value: 'Inter-18pt-Medium'
            },
            {
                name: 'Inter-24pt-Light',
                value: 'Inter-24pt-Light'
            },
            {
                name: 'Gavisans-Bold',
                value: 'Gavisans-Bold'
            },
            {
                name: 'Gavisans-BoldItalic',
                value: 'Gavisans-BoldItalic'
            },
            {
                name: 'Gavisans-Regular',
                value: 'Gavisans-Regular'
            }

        ]

        this.languages = [
            { flag: 'chile', country: 'Chile', code: 'esCL', title: 'CL' },
            { flag: 'colombia', country: 'Colombia', code: 'esCOL', title: 'COL' },
            { flag: 'peru', country: 'Peru', code: 'esPE', title: 'PE' },
            { flag: 'costa-rica', country: 'Costa Rica', code: 'esCAC', title: 'CAC' },
            { flag: 'mexico', country: 'Mexico', code: 'esMX', title: 'MX' },
            { flag: 'brazil', country: 'Brazil', code: 'PT', title: 'BR' },
        ];


        this.selectedLanguage = this.defaultLang ? this.languages.find(l => l.code === this.defaultLang) : this.languages[0];
        this.canTranslate = false;



        //this.mo
        this.subscription = this.searchControl.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            mergeMap(search => this.getModelsWithNewIndexado(search))
        ).subscribe((data) => {
            if(data.status === 200 && data.data && Array.isArray(data.data) && data.data.length > 0 && data.data[0] !== 'No existe indexado') {
                let arr: any[] = [];
                data.data.forEach((i: any) => {
                    if (arr.indexOf(i) === -1) {
                        arr.push(i);
                    }
                });
                const uniqs = data.data.filter(function(item, index, array) {
                    return array.indexOf(item) === index;
                })
                this.filteredOptions = uniqs;
                this.customVersions = arr.map((m: string) => {
                    const arr = m.split('_');
                    return arr[3]
                });
                // this.filteredOptions.unshift('Todas las versiones');
            }
            this._fuseProgressBarService.hide();
        });

        this.filteredOptionsProperties = this.propertiesControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
        this.filteredOptionsProperties2 = this.propertiesControl2.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );

        this.itemCtrl.valueChanges.pipe(

        ).subscribe((data) => {
            console.log('xxxxxxxx', data, ' ', this.customVersions)
        });


    }



    private _filter(value: string): string[] {
        /*const options = {
            includeScore: true,
            // Search in `author` and in `tags` array
            keys: ['key']
        }

        const fuse = new Fuse(this.modelProperties, options)

        const result = fuse.search(value)
        debugger;*/
        const filterValue = value.toLowerCase();
        return this.modelProperties.filter(option => option.label.toLowerCase().indexOf(filterValue) > -1);
    }

    translate() {
        if (this.canTranslate) {
            this.onCreateNewTranslation(this.selectedLanguage);
        }
    }
    setLanguage(lang) {
        this.selectedLanguage = lang;
        // this.isLangAvailable();
        this.onChangeSelectedLang(this.selectedLanguage);

        this.canTranslate = this.selectedLanguage.code !== this.defaultLang;
        this.translate();
    }
    cleanImages(el, field) {
        el[field] = null;
        el.backgroundFromModel = null;
    }

    deleteBackground(el) {
        el.backgroundFromModel = null;
        el.backgroundId = null;
        el.backgroundImageId = null;
    }


    displayFn(model) {
        console.log('displayFN---', model);
        if (model) {
            return model;
        }
    }

    displayFn2(prop) {
        return _.startCase(prop)
    }

    displatFn3(name) {
        console.log('fn3::', name);
        return name;
    }

    loadTemplateFromModel(model) {
        console.log('Model---', model, ' selectedLang:::', this.selectedLanguage);
        this.broadcastService.broadcast({ type: 'template', value: model });
    }

    selectSkuTemplate(version: string): void {
        let sku;
        let languaje;
        let ver;
        if (version === 'ALL') {
            const firstArr = this.filteredOptions[0].split("_");
            version = firstArr[0];
        } else if (version.indexOf('_') !== -1) {
            const arr = version.split('_');
            sku = arr[0];
            languaje = this.languages.find(m => m.code === arr[1]);
            ver = arr[2];
            const custonName = arr.length > 3 ? arr[3] : null;
            if (custonName) {
                this.selectedCustomVersionName = custonName;
            }
            this.setLanguage(languaje);
            this.selectedVersion = ver;
            console.log('ArrVersions:::', this.arrVersions);
        }
        console.log('Llegamos a la petición a getVersions---', version);
        this.indexadoService.getCustomsVersionNames(version)
            .subscribe((data: { data: { list: ICustomVersion[], versionSelected: string } }) => {
                if (data.data && data.data.list && Array.isArray(data.data.list)) {
                    this.onSetArrVersions(data.data.list);
                    this.customVersions = data.data.list.map((m: ICustomVersion) => {
                        const arr = m.id.split('_');
                        return arr[3]
                    });
                } else {
                    this.onSetArrVersions([]);
                    this.customVersions = [];
                }
                if (version.indexOf('_') !== -1) {
                    if (this.arrVersions && Array.isArray(this.arrVersions) && this.arrVersions.length > 0) {
                        const model = {
                            defaultLang: this.selectedLanguage.code,
                            lang: {
                                [this.selectedLanguage.code]: {
                                    content: {
                                        active: true,
                                        page: this.arrVersions[0].page,
                                        rows: this.arrVersions[0].rows,
                                    }
                                }
                            }
                        }
                        this.loadTemplateFromModel(model);
                    }
                } else {
                    if (data.data && data.data.list && Array.isArray(data.data.list)) {
                        // Buscar el default
                        const defaultVersion = data.data.versionSelected;
                        const obj = data.data.list.find((item: ICustomVersion) => item.id === defaultVersion);
                        if (obj) {
                            const arr = defaultVersion.split('_');
                            languaje = this.languages.find(m => m.code === arr[1]);
                            this.setLanguage(languaje);
                            const model = {
                                defaultLang: this.selectedLanguage.code,
                                lang: {
                                    [this.selectedLanguage.code]: {
                                        content: {
                                            active: true,
                                            page: obj.page,
                                            rows: obj.rows,
                                        }
                                    }
                                }
                            }
                            this.loadTemplateFromModel(model);
                        }
                    }
                }
                this._fuseProgressBarService.hide();
            });
    }

    selectVersion(version: { name: string, value: string }) {
        console.log('Version en selectVersion::', version)
        this.onChangeSelectedVersion({
            value: version
        });
    }

    selectCustomVersionName(name: {name: string, version: string}) {
        this.onChangeCustomVersionName({ name });
    }

    onSetArrVersions(versions: any[]) {
        console.log('EN ONSETARRVERSIONS', versions);
        this.setArrVersions({ versions });
    }

    selectCustomName(version: string) {
        if (version === 'Todas las versiones') {

        } else {

        }
        const obj = this.arrVersions.find((v: any) => v.id === version);
        // Construir un modelo con la estructura que espera la función loadTemplateFromModel
        const model = {
            defaultLang: this.selectedLanguage.code,
            lang: {
                [this.selectedLanguage.code]: {
                    content: {
                        active: true,
                        page: obj.page,
                        rows: obj.rows,
                    }
                }
            }
        }

        this.loadTemplateFromModel(model);
    }

    getModelsWithNewIndexado(search) {
        console.log('search...', search);
        this._fuseProgressBarService.show();
        search = typeof search === 'string' ? search : search.sku;
        if (search.indexOf("_") !== -1) {
            const obj = this.arrVersions.find((item: {id: string, page: any, rows: any[]}) => {
                const idArr= item.id.split("_")
                const searchArr = search.split("_")
                if(idArr[1] === searchArr[1] && idArr[2] === searchArr[2] && idArr[2] === searchArr[2] ){
                    return true
                }else{ 
                    return false
                }
            });
            if (obj) {
                console.log('Obj en getodelsWithIndexado---', obj);
                this.onIndexadoChangeHandler(obj);
            }
            return of({
                status: 201
            })
        }
        if (search === 'ALL') {
            if (this.arrVersions.length > 0) {
                console.log('Obj en getodelsWithIndexado---ALL--option', this.arrVersions[0]);
                this.onIndexadoChangeHandler(this.arrVersions[0]);
            }
            return of({
                status: 201
            })
        }
        return this.modelService.getModelsWithNewIndexado(search);
    }

    setLoading = (val: boolean) => {
        this.loadingModels = val;
    }

    /**
     * Constructor
     */

    openDialog(element, field = 'value', multi = false) {
        const dialogRef = this.multimedia.open(MultimediaDialogComponent, {
            data: {
                element: element,
                multi: multi
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (Array.isArray(result)) {
                element[field] = result;
                element = this.formatGalleryElement(element);
            } else {
                /*element[field] = {
                    type : 'google',
                    imageId : result.storageId,
                    thumb : 'https://media.alquimio.cloud/images/@thumbs_512_'+result.storageId+'.webp',
                    medium : 'https://media.alquimio.cloud/images/@thumbs_800_'+result.storageId+'.webp',
                    large : 'https://media.alquimio.cloud/images/@thumbs_1300_'+result.storageId+'.webp'
                }*/
                element[field] = result.storageId;
            }
        });
    }

    formatGalleryElement(element) {
        let imagesObject = [];
        if (Array.isArray(element.value)) {
            _.forEach(element.value, (el) => {
                let newElement = {
                    image: 'https://media.alquimio.cloud/images/@thumbs_512_' + el.storageId + '.webp',
                    thumbImage: 'https://media.alquimio.cloud/images/@thumbs_256_' + el.storageId + '.webp'
                }
                imagesObject.push(newElement.image);
            })
            element.value = imagesObject;
            return element;
        } else {
            console.log('ITEM IS NOT A GALLERY');
        }
    }

    guardar() {
        this.cardChangeHandler.emit(this.card);
    }

    onChangeHoverColor() {
        console.log('this.content.hoverColor', this.content.hoverColor);
        document.querySelector('body').style.cssText = `--my-var: ${this.content.hoverColor}`;
    }

    onColorChanged(): void {

    }



    onChangeRowColumns(val) {
        switch (val) {
            case 'one-column':
                this.row.cards[1].display = false;
                this.row.cards[0].layout = 'one-column';
                this.row.cards[0].width = 100;
                break;
            case 'two-column':
                this.row.cards[1].display = true;
                this.row.cards[0].layout = 'two-column';
                this.row.cards[1].layout = 'two-column';
                this.row.cards[0].width = 50;
                this.row.cards[1].width = 50;
                break;

            case 'three-column':
                this.row.cards[1].display = true;
                this.row.cards[0].layout = 'three-column-first';
                this.row.cards[1].layout = 'three-column-second';
                break;
        }
    }

    onChangeCardColumns(val) {
        switch (val) {
            case '1':
                this.card.contents[1].display = false;
                this.card.contents[1].columns == 'one-column';
                break;
            case '2':
                this.card.contents[1].display = true;
                this.card.contents[1].columns == 'two-column';
                this.card.contents[0].columns == 'two-column';
                break;
        }
    }

    onChangeFirstColumnWidth() {
        this.row.cards[1].width = 100 - this.row.cards[0].width;
    }
    onChangeSecondColumnWidth() {
        this.row.cards[0].width = 100 - this.row.cards[1].width
    }

    onChangeImage(element, property, imageSource, imageValue) {
        switch (imageSource) {
            case 'fromModel':
                //backgroundId
                element[property] = 'https://media.alquimio.cloud/images/@thumbs_512_' + imageValue + '.webp';
                break;
            case 'fromModelLegacy':
                //
                element[property] = '';
                break;
            case 'fromModel':

        }
    }

    onChangeElementType(val) {
        switch (val) {
            case 'spec':
                this.element.desktopWidth = 'one-three';
                break;
            default:
                this.element.desktopWidth = null;
                break;
        }
    }

    waitAndReload(event) {
        const originalSrc = event.target.src;
        if (parseInt(event.target.getAttribute('data-retry'), 10) !== parseInt(event.target.getAttribute('data-max-retry'), 10)) {
            event.target.setAttribute('data-retry', parseInt(event.target.getAttribute('data-retry'), 10) + 1);
            setTimeout(function () {
                event.target.src = originalSrc;
            }, 2000);
        }
    }

    deleteElement(elementId) {
        this.modalDialogService.selectModalType('BUTTONCONFIRM', CONFIRM_DELETE_ELEMENT, confirm => {
            if (confirm) {
                this.content.elements = _.filter(this.content.elements, (element) => {
                    return element.elementId !== elementId;
                })
            }
        })
    }

    initTable(element) {

        if (element.type !== 'table') {
            this.modalDialogService.selectModalType('INFOMODAL', INFO_NOT_TABLE_ELEMENT)
            return;
        }
        if (element.numRows == null && element.numOfColumns == null) {
            return;
        }
        let numOfRows = element.numOfRows;
        let numOfColumns = element.numOfColumns;

        element.rowData = [];
        for (let tableRowId = 0; tableRowId < numOfRows; tableRowId++) {
            let row = {
                tableRowId: tableRowId,
                elementId: element.elementId,
                contentId: element.contentId,
                cardId: element.cardId,
                rowId: element.rowId,
                borderColor: '000000',
                hidden: false,
                height: 'auto',
                cells: []
            }
            for (let columnId = 1; columnId <= numOfColumns; columnId++) {
                let cell = {
                    cellId: `${tableRowId}.${columnId}`,
                    tableRowId: tableRowId,
                    elementId: element.elementId,
                    contentId: element.contentId,
                    fontSize: 18,
                    cardId: element.cardId,
                    rowId: element.rowId,
                    value: `${tableRowId}.${columnId}-Ingrese Texto`
                }
                row.cells.push(cell);
            }
            element.rowData.push(row);
        }
        // debugger;


    }

    onIndexadoChangeHandler(indexado: any) {
        console.log('Indexado en onIndexadoChangeHanlder---', indexado);
        this.indexadoChangeHandler.emit(indexado);
    }

    onEnabledGradient(type: string){
        switch (type) {
            case 'card':
                if (this.card.bgGradient){
                    if (!this.card.bgGradientColors ) {
                        this.card.bgGradientColors = [];
                        this.card.bgGradientColors.push({ color: '#000000', stop: 0 });
                    }
                }
                break;     
            case 'element':
                if (this.element.bgGradient){
                    if (!this.element.bgGradientColors ) {
                        this.element.bgGradientColors = [];
                        this.element.bgGradientColors.push({ color: '#000000', stop: 0 });
                    }
                }
                break;    
            case 'textElement':
                if (this.element.textGradient){
                    if (!this.element.textGradientColors ) {
                        this.element.textGradientColors = [];
                        this.element.textGradientColors.push({ color: '#000000', stop: 0 });
                    }
                }
                break;   
            case 'content':
                if (this.content.textGradient){
                    if (!this.content.textGradientColors ) {
                        this.content.textGradientColors = [];
                        this.content.textGradientColors.push({ color: '#000000', stop: 0 });
                    }
                }
                break; 
            default:
                break;
        }
    }
    addGradientColor(type: string) {
        switch (type) {
            case 'card':
                if (this.card.bgGradientColors && (this.card.bgGradientColors.length  < this.maxGradientRows)) {
                  this.card.bgGradientColors.push({ color: '#000000', stop: 0 });
                }
                break;
            case 'element':
                if (this.element.bgGradientColors && (this.element.bgGradientColors.length  < this.maxGradientRows)) {
                    this.element.bgGradientColors.push({ color: '#000000', stop: 0 });
                }
                break;
            case 'textElement':
                if (this.element.textGradientColors && (this.element.textGradientColors.length  < this.maxGradientRows)) {
                    this.element.textGradientColors.push({ color: '#000000', stop: 0 });
                }
                break;
            case 'content':
                if (this.content.textGradientColors && (this.content.textGradientColors.length  < this.maxGradientRows)) {
                    this.content.textGradientColors.push({ color: '#000000', stop: 0 });
                }
                break;
            default:
                break;
        }

      }
    
      removeGradientColor(index: number, type: string) {
        switch (type) {
            case 'card':
                if (this.card.bgGradientColors.length > 0) {
                  this.card.bgGradientColors.splice(index, 1);
                }
                break; 
            case 'element':
                if (this.element.bgGradientColors.length > 0) {
                    this.element.bgGradientColors.splice(index, 1);
                }
                break; 
            case 'textElement':
                if (this.element.textGradientColors.length > 0) {
                    this.element.textGradientColors.splice(index, 1);
                }
                break;   
            case 'content':
                if (this.content.textGradientColors.length > 0) {
                    this.content.textGradientColors.splice(index, 1);
                }
                break;     
            default:
                break;
        }
      }

}
