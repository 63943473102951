import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    HostListener
} from '@angular/core';
import {
    FuseTranslationLoaderService
} from '@fuse/services/translation-loader.service';
import {
    FuseSidebarService
} from '@fuse/components/sidebar/sidebar.service';
import {
    FuseNavigationService
} from '../../../@fuse/components/navigation/navigation.service';
import {
    locale as english
} from './i18n/en';
import {
    locale as turkish
} from './i18n/tr';
import * as _ from 'lodash';
import {
    MatDialog
} from '@angular/material/dialog';
import {
    ActivatedRoute
} from '@angular/router';
import {
    ModelService
} from '../../services/model.service';
import {
    IndexadoService 
} from '../../services/indexado.service';
import {
    DOCUMENT
} from '@angular/common';
import {
    BroadcastService
} from '../../services/bradcast.services';
import { ModalService } from 'app/services/modal.service';
import { CONFIRM_DELETE_ROW, INFO_SAVED_SUCCESSFULLY } from 'app/Messages';

import {
    environment
} from '../../../environments/environment';
import { ICustomVersion, IVersion } from '../project/project.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'sample',
    templateUrl: './indexado.component.html',
    styleUrls: ['./indexado.component.scss']
})
export class IndexadoComponent implements OnInit {
    folded: boolean = false;
    cardSelected: any;
    rowSelected: any;
    indexado: any;
    indexadoV5: boolean;
    switchIndexadoV5: boolean
    indexado2: any;
    rowSelectedItem: any;
    cardSelectedJson: string;

    selectedRow: any;
    selectedCard: any;
    selectedContent: any;
    selectedElement: any;
    selectedCell: any;
    apiLoaded = false;
    preview = false;
    videoWidth: any;
    availableLanguages: any;
    selectedLang: any;
    translationsList: any;

    newIndexado: any;
    id: string;
    modelData: any;
    modelDataFromService: any
    isMobile: boolean;
    modelProperties: any;
    modelPropertiesValue: any;
    gallery: any;
    modelsWithNewIndexado: any;
    foldNav = false;
    page: any;
    previewType;
    activeTab = 0;
    defaultLang: string = 'esCL';
    indexadoVersions: any;
    selectedVersion:string = 'V5';
    selectedCustomVersionName: string;
    arrVersions: any[];
    customVersions: {name: string, version: string, language: string}[];
    versionSelected: {language: string, version: string}[];
    sku: string;
    environmentState: any;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseNavigationService: FuseNavigationService,
        public multimedia: MatDialog,
        private route: ActivatedRoute,
        private modelService: ModelService,
        private broadcastService: BroadcastService,
        private modalDialogService: ModalService,
        private indexadoService: IndexadoService,
        private sanitizer: DomSanitizer,
        @Inject(DOCUMENT) private document: Document
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.onCreateNewTranslation = this.onCreateNewTranslation.bind(this);
        this.onChangeSelectedLang = this.onChangeSelectedLang.bind(this);
        this.onChangeSelectedVersion = this.onChangeSelectedVersion.bind(this);
        this.setArrVersions = this.setArrVersions.bind(this);
        this.onChangeCustomVersionName = this.onChangeCustomVersionName.bind(this);
        this.indexadoChangeHandler = this.indexadoChangeHandler.bind(this);
        this.environmentState = environment;
        // this.indexadoV5 = false
        // this.switchIndexadoV5 = false
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.detectIsMobile();
    }

    closeMenu() {
        if (!this.foldNav) {
            this._fuseSidebarService.getSidebar('navbar').toggleFold();
            this.foldNav = true;
        }
    }

    clientSideTranslationMutation(modelData, {
        translations
    }) {

        if (translations && translations.length > 0) {
            modelData.rows.forEach((row) => {
                row.cards.forEach((card) => {
                    card.contents.forEach((content) => {
                        content.elements.forEach((element) => {
                            if ((element.type === 'text' || element.type === 'title') && !element.fromModel) {

                                console.log(' element :', element);
                                element.value = translations.reduce((a, b) => {
                                    return a.replace(b.input, b.output);
                                }, element.value);
                            }
                        });
                    });
                });
            });
        }

        return modelData;
    }

    onChangeSelectedLang(args) {
        const {
            code
        } = args;

        this.selectedLang = code;
        this.modelService
            .getTranslations(this.indexado.sku)
            .subscribe((response) => {
                const [translationsList] = response.filter((t) => {
                    return (
                        t.inputLang == 'esCL' /*(this.selectedLang || 'esCL' )*/ &&
                        t.outputLang == code
                    );
                });

                this.translationsList = translationsList || [];


                this.modelService.getModel(this.id).subscribe((modelData) => {
                    let model = modelData;
                    if(modelData.langs && (modelData.langs[code] || modelData.langs[this.defaultLang])){
                        model = modelData.langs[code] || modelData.langs[this.defaultLang];
                    }
                    // debugger;
                    let response = this.formatModelFields(model);
                    this.modelPropertiesValue = response.modelPropertiesValue;
                    this.modelProperties = response.modelProperties;

                    /*
                    this.modelService
                        .getModelWithLang(this.id, code, this.indexadoV5)
                        .subscribe((modelv2Data) => {
                            const {
                                availableLanguages,
                                ...indexado4
                            } = modelv2Data;
                            /*
                            this.indexado = indexado4 || {
                                rows: []
                            };
                            this.availableLanguages = availableLanguages;
                            
                            // property values
                        });
                        */
                });

            });


            let defaultVersion;
            this.defaultLang = code;
            let isSetIndexado = false;
            if (this.versionSelected) {
                defaultVersion = this.versionSelected.find((ver: {language: string, version: string}) => ver.language === code);
                if (!defaultVersion) {
                    defaultVersion = {
                        language: code,
                        version: this.selectedVersion
                    }
                    console.log('pasa por 222');
                    const newElement = { 
                        rows: [],
                        page: this.indexado.page || {},
                        id: `${this.sku}_${this.selectedLang}_${this.selectedVersion}_default`
                    };
                    this.versionSelected.push({language: code, version: 'default'});
                    this.customVersions.push({name: 'default', version: 'V4', language: code});
                    this.customVersions.push({name: 'default', version: 'V5', language: code});
                    this.arrVersions = [...this.arrVersions, newElement];
                    this.indexado = this.arrVersions[this.arrVersions.length - 1];
                    isSetIndexado = true;
                } else {
                    const obj = this.arrVersions.find((v: ICustomVersion) => v.id === `${this.sku}_${this.selectedLang}_${this.selectedVersion}_default`);
                    if (obj) {
                        this.indexado = obj;
                    }
                }
                this.selectedCustomVersionName = 'default';
            }
      

            let arr = [];
            if (this.arrVersions) {
                this.arrVersions.forEach((m: IVersion) => {
                    const ar = m.id.split('_');
                    if(ar[1] === code && arr.indexOf(ar[3]) === -1) {
                        arr.push({name: ar[3], version: ar[2], language: ar[1]});
                    }
                });
            } else {
                this.arrVersions = [];
            }
            const objV4 = arr.find((item: {name: string, version: string, language: string}) => item.name == 'default' && item.version == 'V4' && item.language == code)
            if (!objV4) {
                arr.push({name: 'default', version: 'V4', language: code});
            }
            const objV5 = arr.find((item: {name: string, version: string, language: string}) => item.name == 'default' && item.version == 'V5' && item.language == code)
            if (!objV5) {
                arr.push({name: 'default', version: 'V5', language: code});
            }

            if (arr.length === 0) {
                arr.push(
                    {
                        version: 'V4',
                        name: 'default',
                        language: code,
                    },
                    {
                        version: 'V5',
                        name: 'default',
                        language: code,
                    }
                )
            }
            this.customVersions = arr;
            if (!isSetIndexado) {
                const obj = this.arrVersions.find((v: {id: string, rows: any[], page: any}) => v.id === `${this.sku}_${code}_${defaultVersion.version}_default`);
                if (obj) {
                    this.indexado = obj;
                }
            }
            if (!defaultVersion) {
                defaultVersion = {
                    language: code,
                    version: this.selectedVersion
                }
            }
            this.selectedVersion =  defaultVersion.version;
            // this.onChangeCustomVersionName({ name: 'default'});
            this.selectedCustomVersionName = 'default';

    }

    onChangeSelectedVersion(args) {
        const { value } = args;
        const beforeIndexadoV5 = this.indexadoV5;
        this.selectedVersion = value;
        this.indexadoV5 = value.indexOf("V5") !== -1;
        this.switchIndexadoV5 = value.indexOf("V5") !== -1;
        if (beforeIndexadoV5 !== this.indexadoV5 && this.arrVersions && Array.isArray(this.arrVersions)) {
            const obj = this.arrVersions.find((v: {id: string, rows: any[], page: any}) => v.id === `${this.sku}_${this.selectedLang}_${value}_default`);
            if (obj) {
                this.indexado = obj;
            } else {
                this.indexado = {
                    rows: [],
                    page: {},
                    id: `${this.sku}_${this.selectedLang}_${value}_default`
                }
                this.arrVersions.push(this.indexado);
            }
            this.selectedCustomVersionName = 'default';
        }

    }

    onChangeCustomVersionName(args) {
        const { name } = args;
        this.selectedCustomVersionName = name;
        let pos = -1;
        this.arrVersions.forEach((i: {id: string, rows: any[], page: any}, index: number) => {
            const arr = i.id.split("_");
            if(arr[3] === name && arr[2] === this.selectedVersion && arr[1] === this.selectedLang) {
                pos = index;
            } 
        });
        if (pos > -1) {
            console.log('Pos----', pos, name);
            this.indexado = this.arrVersions[pos];
        } else {
            console.log('selectedLang----', this.selectedLang, ' selectedVersion ----', this.selectedVersion);
        }
    }

    setArrVersions(args) {
        console.log('args---', args);
        const { versions, addVersion } = args;
        if (!addVersion) {
            let newsVersions = [];
            if (!this.arrVersions) {
                newsVersions = versions;  
            } else {
                versions.forEach((v: ICustomVersion) => {
                    const arr = v.id.split("_");
                    arr[0] = this.sku;
                    v.id = arr.join("_");
                    let i = -1;
                    let obj = this.arrVersions.find((ver: ICustomVersion, index: number) => {
                        if(ver.id === v.id) {
                            i = index;
                            return ver;
                        }
                    });
                    if (!obj) {
                        newsVersions.push(JSON.parse(JSON.stringify(v)));
                    } else {
                        this.arrVersions[i] = v;
                    }
                });
            }
            this.arrVersions = this.arrVersions ? this.arrVersions.concat(newsVersions) : versions;
            /*
            this.arrVersions = versions;
            */
            const arr = [];
            let obj = {};
            this.arrVersions.forEach((v: ICustomVersion) => {
                const ar = v.id.split("_");
                if (arr.indexOf({name: ar[3], version: ar[2], language: ar[1]}) === -1) {
                    arr.push({name: ar[3], version: ar[2], language: ar[1]});
                }
                if (ar[1] === this.selectedLang && ar[2] === this.selectedVersion && ar[3] === 'default') {
                    obj = v;
                }
            });
            this.customVersions = arr;
            this.indexado = obj;
        } else {
            let newElement: any = {}
            if (this.indexado.page?.stylesConfig){
                let {stylesConfig, ...rest} = this.indexado.page;
                newElement = { 
                    rows: [...this.indexado.rows],
                    page: rest,
                    id: `${this.sku}_${this.selectedLang}_${this.selectedVersion}_${versions}`
                };
            }else{
                newElement = { 
                    rows: [...this.indexado.rows],
                    page: this.indexado.page,
                    id: `${this.sku}_${this.selectedLang}_${this.selectedVersion}_${versions}`
                };
            }
            
            this.customVersions.push({name: versions, version: this.selectedVersion, language: this.selectedLang});
            this.selectedCustomVersionName = versions;
            this.arrVersions.push(JSON.parse(JSON.stringify(newElement)));
            this.indexado = this.arrVersions[this.arrVersions.length - 1];
        }
    }

    onCreateNewTranslation(args) {

        console.log('onCreateNewTranslation', args);
        const {
            code
        } = args;
        this.selectedLang = code;
        console.log(this.availableLanguages);
        if (this.availableLanguages.indexOf(code) == -1) {
            this.modelService
                .createModelWithLang(this.id, code)
                .subscribe((modelv2Data) => {
                    const {
                        availableLanguages,
                        ...indexado4
                    } = modelv2Data;
                    /*
                    this.indexado = this.clientSideTranslationMutation(
                        indexado4,
                        this.translationsList
                    ) || {
                        rows: []
                    };
                    */

                    this.availableLanguages = availableLanguages;

                    this.modelService
                        .saveModelv2(this.id, this.selectedLang || 'esCL', this.indexado, this.switchIndexadoV5)
                        .subscribe((modelData) => {
                            //alert("Traducido Con exito");
                        });
                });
        } else {
            this.modelService
                .saveModelv2(
                    this.id,
                    this.selectedLang || 'esCL',
                    this.clientSideTranslationMutation(
                        this.indexado,
                        this.translationsList
                    ),
                    this.switchIndexadoV5
                )
                .subscribe((modelData) => {
                    // alert("Traducido Con exito");
                });
        }
    }

    onChangeDefault() {
        this.arrVersions.forEach((v: ICustomVersion) => {
            const arr = v.id.split("_");
            if (arr[2] !== this.selectedVersion && arr[1] === this.selectedLang) {
                v.default = false;
            }
        });
    }

    openIndexado() {
        if (this.selectedCustomVersionName && this.selectedCustomVersionName!=='default'){
            window.open(
                `${environment.indexadoUrl}/spec?modelId=${this.id}&lang=${(this.selectedLang || 'esCL')}&version=${this.selectedVersion}_${this.selectedCustomVersionName}`,
                '_blank'
            )
        }else{
            window.open(
                `${environment.indexadoUrl}/spec?modelId=${this.id}&lang=${(this.selectedLang || 'esCL')}&version=${this.selectedVersion}`,
                '_blank'
            )
        }
    }


    ngOnInit() {
        console.log('environment---', this.environmentState);
        this.indexadoVersions = [
            {
                name:'Versión 4.0',
                value:'V4'
            },
            {
                name:'Versión 5.0',
                value:'V5'
            },
        ]
        if (!this.apiLoaded) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            this.apiLoaded = true;
        }
        this.indexado = {};
        this.detectIsMobile();
        this.id = this.route.snapshot.paramMap.get('id');
        /*
        this.broadcastService.subscribe((val) => {
            if (val.type === 'template' && val.value) {
                console.log('Maybe here');
                let defaultLang = this.selectedLang || val.value.defaultLang;
                let otherLang = defaultLang === 'esCL' ? 'PT' : 'esCL';
                if(!val.value.lang[defaultLang]){
                    this.indexado = val.value.lang[otherLang].content;
                } else {
                    this.indexado = val.value.lang[defaultLang].content;
                }
                if (!this.indexado.page) {
                    this.indexado.page = {};
                } 
            }
        });
        */

        this.modelService.getModel(this.id).subscribe((modelData) => {
            this.modelDataFromService = modelData
            let modelDataToFormat = modelData;
            console.log('modelData 275:',modelData)

            let otherLang = this.defaultLang === 'esCL' ? 'PT' : 'esCL';
            if(!modelData.langs[this.defaultLang] && modelData.langs[otherLang]){
                this.defaultLang = this.selectedLang = otherLang;
            }
            this.selectedLang = this.defaultLang;
            if(modelData.langs &&  modelData.langs[this.defaultLang]){
                let propertiesOfLang = modelData.langs[this.defaultLang];
                _.forEach(propertiesOfLang, (property, key) => {
                    modelDataToFormat[key] = property;
                })
            }

            let response = this.formatModelFields(modelDataToFormat);
            this.modelPropertiesValue = response.modelPropertiesValue;
            this.modelProperties = response.modelProperties;
            this.sku = modelData.sku || '';
            this.availableLanguages = ['esCL', 'PT'];
            // adding v2

            /* LLAMADO ANTERIOR A INDEXADODOC, SE REEMPLAZA POR LLAMADO A INDEXADO-SERVICE
            this.modelService.getModelWithLang(this.id, null, this.indexadoV5).subscribe(modelv2Data => {
                console.log('modelv2Data 291:',modelv2Data)
                const {
                    indexado_version,
                    availableLanguages,
                    defaultLang,
                    ...indexado4
                } = modelv2Data;

                this.indexado = indexado4 || {
                    rows: []
                };
                
                this.indexadoV5 = indexado_version === 'v5' ? true : false        
                if (this.indexadoV5) {
                    this.switchIndexadoV5 = true
                    this.setIndexadoV5()
                }     
                this.availableLanguages = availableLanguages;
                this.defaultLang = defaultLang;
                this.selectedLang = this.defaultLang;
                let response = this.formatModelFields(modelData.langs[this.defaultLang]);
                this.modelPropertiesValue = response.modelPropertiesValue;
                this.modelProperties = response.modelProperties;


            }, error => {
                if (!this.indexado.page) {
                    this.indexado.page = {
                        backgroundColor : '#FFFFFF',
                        paddingTop:0,
                        paddingRight:0,
                        paddingLeft:0,
                        paddingBottom:0
                    };
                }
            });
            */
           this.indexadoService.getModel(this.id).subscribe(modelv2Data => {
             console.log('modelv2Data:',modelv2Data);
             if (modelv2Data.data && modelv2Data.data.list && Array.isArray(modelv2Data.data.list) && modelv2Data.data.list.length > 0){
                let defaultVersion;
                if (modelv2Data.data.versionSelected) {
                    defaultVersion = modelv2Data.data.versionSelected.find((ver: {language: string, version: string}) => ver.language === this.selectedLang);
                } else {
                    defaultVersion = modelv2Data.data.list[0];
                }
                this.onChangeSelectedVersion({value: defaultVersion.version});      
                this.setArrVersions({versions: modelv2Data.data.list});
                this.arrVersions.forEach((v: ICustomVersion) => {
                    const arr = v.id.split("_");
                    if (arr[2] === defaultVersion.version) {
                        v.default = true;
                    }
                });
                let arr = [];
                modelv2Data.data.list.forEach((m: IVersion) => {
                    const ar = m.id.split('_');
                    if(arr.indexOf(ar[3]) === -1) {
                        arr.push({name: ar[3], version: ar[2], language: ar[1]})
                    }
                });
                this.customVersions = arr;
                this.versionSelected = modelv2Data.data.versionSelected;
                this.onChangeCustomVersionName({ name: 'default'});
                this.onChangeSelectedLang({code: this.selectedLang});
             }
           }, error => {
                if (!this.indexado.page) {
                    this.indexado.page = {
                        backgroundColor : '#FFFFFF',
                        paddingTop:0,
                        paddingRight:0,
                        paddingLeft:0,
                        paddingBottom:0
                    };
                }
            });
            if (!this.indexado.page) {
                this.indexado.page = {
                    backgroundColor : '#FFFFFF',
                    paddingTop:0,
                    paddingRight:0,
                    paddingLeft:0,
                    paddingBottom:0
                };
            }
        });
    }

    setContentVersion(version:string){
        switch (version) {
            case 'V4':
                return 'content';
            case 'V5':
                return 'contentV5';       
            default:
                return 'content';
        }
    }
    setBgWithOpacity(bg: any,op:any){
        const rgbaColor = this.hexToRgba(bg,op);
        return rgbaColor == null ? 'rgba(230,230,230,1)' : `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${rgbaColor.a})`
    }
    
    hexToRgba(hex:any,alpha:any) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        // console.log('result',result)
        return result && result[1] && result[2] && result[3] ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: parseFloat(alpha)
        } : null;
    }


    getCardBackgroundUrl(backgroundFromModel) {
        if (backgroundFromModel) {
            if (
                this.modelPropertiesValue[backgroundFromModel] &&
                this.modelPropertiesValue[backgroundFromModel].indexOf('https') > -1
            ) {
                return 'url(' + this.modelPropertiesValue[backgroundFromModel] + ')';
            } else {
                return (
                    `url(${environment.azureImagesURL}/${environment.azureImagesContainer}/
                    ${this.modelPropertiesValue[backgroundFromModel]})`
                );
            }
        } else {
            return null;
        }
    }

    getValueFromModel(keyOfProperty) {
        let obj = _.find(this.modelData, (property) => {
            return property.key == keyOfProperty;
        });
        return obj.value;
    }

    handleShowOrHide(element) {
        if (typeof element.state !== 'boolean') {
            element.state = true;
        }
        element.state = !element.state;

        const idToSearch = element.action.idToShowOrHide;
        _.forEach(this.indexado.rows, (row) => {
            this.showOrHideByID(row, idToSearch);
            _.forEach(row.cards, (card) => {
                this.showOrHideByID(card, idToSearch);
                _.forEach(card.contents, (content) => {
                    this.showOrHideByID(content, idToSearch);
                    _.forEach(content.elements, (curElement) => {
                        this.showOrHideByID(curElement, idToSearch);
                    });
                });
            });
        });
    }

    showOrHideByID(el, idToSearch) {
        if (el.customId === idToSearch) {
            if (typeof el.hide == 'undefined') {
                el.hide = false;
                el.startHidden = false;
            } else {
                el.hide = !el.hide;
                el.startHidden = false;
            }
        }
    }

    formatModelFields(data) {
        let modelProperties = [];
        let modelPropertiesValue = {};
        if (data.diferenciadores) {
            let diferenciadores = this.getDiferenciadores(data.diferenciadores);
            data = {
                ...data,
                ...diferenciadores
            };
        }
        _.forEach(data, (property: any, key) => {
            if (typeof property == 'string') {
                let label;
                let secondLabel;
                let prop;
                if (key.lastIndexOf('|') > -1) {
                    label = _.startCase(key.substring(0, key.lastIndexOf('|')));
                    secondLabel = key.substring(key.lastIndexOf('|') + 1);
                } else {
                    label = _.startCase(key);
                }

                prop = {
                    label: label,
                    secondLabel: secondLabel,
                    value: property,
                    key: key
                };

                modelProperties.push(prop);
                modelPropertiesValue[key] = property;
            }
        });
        modelPropertiesValue['fullname'] = data.nameFirstLine || data.model || data.name || data.nombre;
        if (
            data.nameSecondLine &&
            typeof data.nameSecondLine != 'undefined' &&
            data.nameSecondLine != ''
        ) {
            modelPropertiesValue['fullname'] =
                modelPropertiesValue['fullname'] + ' ' + data.nameSecondLine;
        }
        
            modelProperties.push({
                label: 'Nombre Completo',
                value: modelPropertiesValue['fullname'],
                key: 'fullname'
            });

        if (data.galeria) {
            let galeria = this.getGallery(data.galeria);
            modelProperties.push({
                label: 'Galeria',
                value: galeria,
                key: 'galleryLegacy'
            });
            modelPropertiesValue['galleryLegacy'] = galeria;
        }

        if (data.sindicado && data.sindicado.video) {
            modelProperties.push({
                label: 'video',
                value: data.sindicado.video,
                key: 'video1'
            });
            modelPropertiesValue['video'] = data.sindicado.video;
        }
        if (data.sindicado && data.sindicado.video2) {
            modelProperties.push({
                label: 'video2',
                value: data.sindicado.video2,
                key: 'video2'
            });
            modelPropertiesValue['video2'] = data.sindicado.video2;
        }
        if (data.sindicado && data.sindicado.video3) {
            modelProperties.push({
                label: 'video3',
                value: data.sindicado.video3,
                key: 'video3'
            });
            modelPropertiesValue['video3'] = data.sindicado.video3;
        }

        modelProperties = _.sortBy(modelProperties, 'label');

        return {
            modelProperties: modelProperties,
            modelPropertiesValue: modelPropertiesValue
        };
    }

    getDiferenciadores(dataDiferenciadores) {
        this.removeMalFormedDifs(dataDiferenciadores)
        let newDataDif = {};
        _.forEach(dataDiferenciadores, (dif, index) => {
            newDataDif[`diferenciador${index}Titulo`] = dif.titulo;
            newDataDif[`diferenciador${index}Descripcion`] = dif.descripcion;

            if (dif.imagen && dif.imagen[0]) {
                newDataDif[`diferenciador${index}ImagenIcono`] = dif.imagen[0].name;
            }
            if (dif.imagenBody && dif.imagenBody[0]) {
                newDataDif[`diferenciador${index}Imagen Contenido`] =
                    dif.imagenBody[0].name;
            }
        });
        return newDataDif;
    }

    removeMalFormedDifs(dataDiferenciadores) {
        dataDiferenciadores = dataDiferenciadores.filter(dif => {
            return typeof dif == 'object'
        })
    }

    getGallery(dataGallery) {
        let newDataGallery = [];
        _.forEach(dataGallery, (imagen: {name: string}, index) => {
            let newImagen = {
                image: `${environment.azureImagesURL}/${environment.azureImagesContainer}/${imagen.name}`,
                thumbImage: `${environment.azureImagesURL}/${environment.azureImagesContainer}/thumb-${imagen.name}`
            };
            newDataGallery.push(newImagen.thumbImage);
        });
        return newDataGallery;
    }

    pageWidth;
    showAllElements = true;

    forceSize(forceSize) {
        if (forceSize === 'mobile') {
            this.detectIsMobile(true);
            this.showAllElements = false;
        } else if (forceSize === 'desktop') {
            this.detectIsMobile(false);
            this.showAllElements = false;
        } else if (forceSize === 'todo') {
            this.showAllElements = true;
            this.detectIsMobile(false);
        }
    }

    revertedColumns = false;
    cellWidth;

    detectIsMobile(forceMobile = false) {
        try {
            let indexadoDivCol: HTMLCollection =
                this.document.getElementsByClassName('indexado2');

            // @ts-ignore
            const indexadoDiv: HTMLElement = _.first(indexadoDivCol);
            let width: number;
            if (forceMobile == true) {
                width = 400;
            } else {
                width = indexadoDiv.offsetWidth;
            }
            this.cellWidth = Math.round(width / 2);
            if (forceMobile == true) {
                this.isMobile = true;
                this.videoWidth = '350px';
                if (!this.revertedColumns) {
                    _.map(this.indexado.rows, (row, index) => {
                        // @ts-ignore
                        if (index % 2 == 0 && index > 0 &&
                            row.columns == 'two-column' &&
                            row.invertOnMobile !== 'false'
                        ) {
                            row.cards = _.reverse(row.cards);
                        }
                    });
                }
                this.revertedColumns = true;
            } else {
                if (this.revertedColumns) {
                    _.map(this.indexado.rows, (row, index) => {
                        // @ts-ignore
                        if (index % 2 == 0 && index > 0 &&
                            row.columns == 'two-column' &&
                            row.invertOnMobile !== 'false'
                        ) {
                            row.cards = _.reverse(row.cards);
                        }
                    });
                    this.revertedColumns = false;
                }
                this.videoWidth = (width - 10) / 2 + 'px';
                this.isMobile = false;
            }
        } catch (e) {
            if (this.isMobile) {
                this.videoWidth = '450px';
            } else {
                this.videoWidth = '100%';
            }
        }
    }

    addRow(): void {
        let rowId = this.indexado.rows ? this.indexado.rows.length + 1 : 0;
        let newRow = {
            rowId: rowId,
            columns: 'one-column',
            marginTop: 0,
            marginBottom: 0,
            cards: []
        };
        newRow.cards.push(this.createCard(newRow));
        newRow.cards.push(this.createCard(newRow, false));
        if (!this.indexado.rows) {
            this.indexado.rows = [];
        }

        this.indexado.rows.push(newRow);
        // debugger;
    }

    reorganize(): void{
        console.log("El indexado antes de reorganizar:::", this.indexado.rows)
        this.indexado.rows = this.indexado.rows.map((row: { cards: any[]; }, i: any) => {
            let newRow = { ...row, rowId: i };
            newRow.cards =newRow.cards.map((card: any, j: any) =>{
                let newcard = {...card, cardId: `${i}.${j}`, rowId: i}
                newcard.contents = newcard.contents.map((content: any, k: any) => {
                    let newContent = {...content, contentId: `${i}.${j}.${k}`, cardId: `${i}.${j}`, rowId: i}
                    newContent.elements = newContent.elements.map((element: any, l: any) => {
                        return {...element, rowId: i, cardId: `${i}.${j}`, contentId: `${i}.${j}.${k}`, elementId: `${i}.${j}.${k}.${l}`}
                    })
                    return newContent;
                })
                return newcard;
            })
            return newRow;
          });
          console.log("El indexado despues de reorganizar:::", this.indexado.rows)
    }

    createCard(row, display = true) {
        let cardId = `${row.rowId}.${row.cards.length + 1}`;
        let newCard = {
            cardId: cardId,
            rowId: row.rowId,
            display: display,
            columns: 'one-column',
            minHeight: 200,
            contents: []
        };
        newCard.contents.push(this.createContent(newCard));
        newCard.contents.push(this.createContent(newCard, false));

        return newCard;
    }

    createContent(card, display = true) {
        let contentId = `${card.cardId}.${card.contents.length + 1}`;
        let content = {
            contentId: contentId,
            rowId: card.rowId,
            cardId: card.cardId,
            animationBackground: 'none',
            animationText: 'none',
            animationContent: 'none',
            yAlign: 'y-center',
            xAlign: 'x-center',
            columns: 'one-column',
            hideOnMobile: false,
            hideOnDesktop: false,
            display: display,
            elements: []
        };

        return content;
    }

    createElement(content) {
        let elementId = `${content.contentId}.${content.elements.length + 1}`;
        let element = {
            elementId: elementId,
            rowId: content.rowId,
            cardId: content.cardId,
            contentId: content.contentId
        };
        content.elements.push(element);
        return content;
    }

    onSelectRow(row, $event) {
        this.closeMenu();
        this.selectedCard = this.selectedContent = this.selectedElement = null;
        this.selectedRow = row;

        setTimeout(() => {
            this.activeTab = 1;
        }, 500);
    }

    onSelectCard(card, $event) {
        this.closeMenu();
        this.selectedRow = this.selectedContent = this.selectedElement = null;
        this.selectedCard = card;

        this.selectedRow = _.find(this.indexado.rows, (row) => {
            return row.rowId == card.rowId;
        });
        this.logSelectedContent();
        setTimeout(() => {
            this.activeTab = 2;
        }, 500);
    }

    onSelectContent(content, $event) {
        this.closeMenu();
        this.selectedRow = this.selectedCard = this.selectedElement = null;
        this.selectedContent = content;

        this.selectedRow = _.find(this.indexado.rows, (row) => {
            return row.rowId == content.rowId;
        });
        this.selectedCard = _.find(this.selectedRow.cards, (card) => {
            return card.cardId == content.cardId;
        });
        setTimeout(() => {
            this.activeTab = 3;
        }, 500);
    }

    onSelectElement(element, $event) {
        this.closeMenu();
        this.selectedRow = null;
        this.selectedCard = null;
        this.selectedContent = null;
        this.selectedElement = element;

        this.selectedRow = _.find(this.indexado.rows, (row) => {
            return row.rowId == element.rowId;
        });
        this.selectedCard = _.find(this.selectedRow.cards, (card) => {
            return card.cardId == element.cardId;
        });
        this.selectedContent = _.find(this.selectedCard.contents, (content) => {
            return content.contentId == element.contentId;
        });
        this.logSelectedContent();
        setTimeout(() => {
            this.activeTab = 4;
        }, 500);
    }

    selectedTableRow;

    onSelectCell(cell, $event) {
        this.closeMenu();

        this.selectedRow = null;
        this.selectedCard = null;
        this.selectedContent = null;
        this.selectedElement = null;
        this.selectedTableRow = null;
        this.selectedCell = cell;

        this.selectedRow = _.find(this.indexado.rows, (row) => {
            return row.rowId == cell.rowId;
        });
        this.selectedCard = _.find(this.selectedRow.cards, (card) => {
            return card.cardId == cell.cardId;
        });
        this.selectedContent = _.find(this.selectedCard.contents, (content) => {
            return content.contentId == cell.contentId;
        });
        this.selectedElement = _.find(this.selectedContent.elements, (element) => {
            return element.elementId == cell.elementId;
        });
        this.selectedTableRow = _.find(
            this.selectedElement.rowData,
            (rowOfTable) => {
                return rowOfTable.tableRowId == cell.tableRowId;
            }
        );
        setTimeout(() => {
            this.activeTab = 4;
        }, 500);
    }

    moveElement(element, action) {
        let elementsOfContents = this.selectedContent.elements;
        let indexCurrentElement = _.findIndex(elementsOfContents, (el: any) => {
            return el.elementId == element.elementId;
        });
        let indexPrevElement = indexCurrentElement - 1;
        let indexNextElement = indexCurrentElement + 1;

        if (action == 'up' && !this.selectedContent.elements[indexPrevElement]) {
            return;
        }
        if (action == 'down' && !this.selectedContent.elements[indexNextElement]) {
            return;
        }

        if (action === 'up') {
            let prevElement = _.cloneDeep(
                this.selectedContent.elements[indexPrevElement]
            );
            this.selectedContent.elements[indexPrevElement] = element;
            this.selectedContent.elements[indexCurrentElement] = prevElement;
        }
        if (action === 'down') {
            let nextElement = _.cloneDeep(
                this.selectedContent.elements[indexNextElement]
            );
            this.selectedContent.elements[indexNextElement] = element;
            this.selectedContent.elements[indexCurrentElement] = nextElement;
        }
    }
    reverseRow(row){
        console.log("inverRow:::",row.cards);
        row.cards.reverse();
        //[row.cards[0], row.cards[1]] = [row.cards[1], row.cards[0]];
    }
    moveRow(row, action) {
        let indexCurrentRow = _.findIndex(this.indexado.rows, (curRow: any) => {
            return row.rowId == curRow.rowId;
        });
        let indexPrevRow = indexCurrentRow - 1;
        let indexNextRow = indexCurrentRow + 1;

        if (action == 'up' && !this.indexado.rows[indexPrevRow]) {
            return;
        }
        if (action == 'down' && !this.indexado.rows[indexNextRow]) {
            return;
        }

        if (action === 'up') {
            let prevRow = _.cloneDeep(this.indexado.rows[indexPrevRow]);
            this.indexado.rows[indexPrevRow] = row;
            this.indexado.rows[indexCurrentRow] = prevRow;
        }
        if (action === 'down') {
            let nextRow = _.cloneDeep(this.indexado.rows[indexNextRow]);
            this.indexado.rows[indexNextRow] = row;
            this.indexado.rows[indexCurrentRow] = nextRow;
        }
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    cloneRow(row) {
        let indexCurrentRow = _.findIndex(this.indexado.rows, (curRow: any) => {
            return row.rowId == curRow.rowId;
        });
        let newRow = _.cloneDeep(row);
        let newRowId = this.getRandomInt(1, 100);
        newRow.rowId = newRow.rowId + newRowId;
        _.forEach(newRow.cards, (card) => {
            card.rowId = newRow.rowId;
            _.forEach(card.contents, (content) => {
                content.rowId = newRow.rowId;
                _.forEach(content.elements, (element) => {
                    element.rowId = newRow.rowId;
                    if (element.type == 'table') {
                        _.forEach(element.rowData, (curRowTable) => {
                            curRowTable.rowId = newRow.rowId;
                            _.forEach(curRowTable.cells, (cell) => {
                                cell.rowId = newRow.rowId;
                            });
                        });
                    }
                });
            });
        });

        this.insert(this.indexado.rows, indexCurrentRow + 1, newRow);
    }

    insert(arr, index, newItem) {
        this.indexado.rows = [
            // part of the array before the specified index
            ...this.indexado.rows.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...this.indexado.rows.slice(index)
        ];
    }

    logSelectedContent() {
        console.log('  this.selectedRow: ', this.selectedRow);
        console.log('  this.selectedCard : ', this.selectedCard);
        console.log('  this.selectedContent: ', this.selectedContent);
        console.log('  this.selectedElement: ', this.selectedElement);
        console.log('  this.selectedTableRow: ', this.selectedTableRow);
        console.log('  this.selectedCell: ', this.selectedCell);
    }

    onHoverContent(hoverBackground, hoverColorText, content) {
        content.displayOver = true;
        document.querySelector(
            'body'
        ).style.cssText = `--hoverBackground: ${hoverBackground}; --textColor: ${hoverColorText}`;
        console.log(this.arrVersions, this.indexado);
    }

    onHoverContentLeave(content) {
        content.displayOver = false;
    }

    mouseLeave(color) {
        console.log('mouse leave :' + color);
    }

    save() {
        console.log(this.arrVersions);
        this.modalDialogService.selectModalType('BUTTONCONFIRM',
        `Esta seguro que desea guardar la información del indexado?`,
                    (resp: any) => {
                        if (resp) {
                            if (!this.arrVersions || this.arrVersions.length === 0) {
                                let version = '';
                                if (this.selectedCustomVersionName) {
                                    version = `${this.sku}_${this.selectedLang}_${this.selectedVersion}_${this.selectedCustomVersionName}`;
                                } else {
                                    version = `${this.sku}_${this.selectedLang}_${this.selectedVersion}`;
                                }
                                this.setArrVersions({ 
                                    versions: [
                                        {
                                            rows: this.indexado.rows,
                                            page: this.indexado.page,
                                            id: version
                                        }
                                    ]
                                });
                            }
                            let indexadoState = false;
                            let versionSelected = [
                                {
                                    language: 'esCL',
                                    version: 'V5'
                                },
                                {
                                    language: 'PT',
                                    version: 'V5'
                                }
                            ];
                            let arrVersions = [];
                            this.arrVersions.forEach((v: {rows: any[], page: any, id: string, active?: boolean, default?: boolean} ) => {
                                if (v.default) {
                                    indexadoState = true;
                                    const arr = v.id.split("_");
                                    const obj = versionSelected.find((ver: any) => ver.language === arr[1]);
                                    if (obj) {
                                        obj.version = arr[2];
                                    }
                                    delete v.default;
                                    arrVersions = [...arrVersions, {...v}];
                                    v.default = true;
                                    v.active = true;
                                } else {
                                    delete v.default;
                                    arrVersions = [...arrVersions, {...v}];
                                    v.default = false;
                                }
                            });
                            
                            this.indexadoService
                            .save(this.id, arrVersions, versionSelected, this.sku, indexadoState)
                            .subscribe((modelData) => {
                                this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY)
                            });                
                        }
                    }
                )
        /*
        this.modelService
            .saveModelv2(this.id, this.selectedLang || 'esCL', this.indexado, this.switchIndexadoV5)
            .subscribe((modelData) => {
                this.modalDialogService.selectModalType('INFOMODAL', INFO_SAVED_SUCCESSFULLY)
            });
            */
        
    }

    loadSaved() {
        let indexadoJson = localStorage.getItem('indexado');
        //this.indexado = JSON.parse(indexadoJson);
        //this.detectIsMobile();
    }

    /**
     * Toggle sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
        this.folded = !this.folded;
    }

    openSidebar(): void {
        this.folded = !this.folded;
    }

    selectCard(card: any): void {
        this.cardSelected = card;
        this.cardSelectedJson = JSON.stringify(card);
        /*this.rowSelectedItem = _.find(this.indexado.rows,(row)=>{return row.id === card.row});*/
    }

    indexadoChangeHandler(indexado: any) {
        console.log('Llego aquí', indexado);
        this.indexado = indexado;
    }

    rowChangedHandler(row: any) {
        this.selectedRow = row;
    }

    pageChangedHandler(page: any) {
        this.indexado.page = page;
    }

    cardChangeHandler(card: any) {
        this.selectedCard = card;
    }

    contentChangeHandler(content: any) {
        this.selectedContent = content;
    }

    elementChangeHandler(element: any) {
        this.selectedElement = element;
    }

    cellChangeHandler(cell: any) {
        this.selectedCell = cell;
    }

    tableRowChangeHandler(tableRow: any) {
        this.selectedTableRow = tableRow;
    }

    filterContents(contents: any[]): any[] {
        return _.filter(contents, (content) => {
            return content.display === true;
        });
    }

    filterCards(cards: any[]): any[] {
        return _.filter(cards, (card) => {
            return card.display === true;
        });
    }

    deleteRow(rowId) {
        this.modalDialogService.selectModalType('BUTTONCONFIRM', CONFIRM_DELETE_ROW, confirm => {
          if (confirm) {
            this.indexado.rows = _.filter(this.indexado.rows, (row) => {
                return row.rowId != rowId;
            });
          }
        })
    }
}